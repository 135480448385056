<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{$t('i18nn_bced63832451e050')}}</span>
									<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
										<el-button type="warning" circle icon="el-icon-download" size="mini"
											@click="exportExcel()"></el-button>
									</el-tooltip>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<!-- <el-button  @click="updatePartnerDataAction($event,$t('4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">{{$t('i18nn_4f62f8d07bb4054f')}}</el-button> -->
								<!-- <span v-if="!isSel"> -->
								<!-- <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null, $t('i18nn_24c8f46012a25c89'))">{{$t('c0246c55b9cac963')}}</el-button> -->

								<!-- <el-button @click="completeAction($event,null)" type="danger" size="small" icon="el-icon-finished">{{$t('i18nn_de57dcdfc3e01860')}}</el-button> -->
								<!-- </span> -->
								<!--右边容器-->
								<!-- <slot name="topRight"></slot> -->
								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<div>
						<div class="filterCon" style="">
							<!-- <el-row type="flex" justify="end"> -->
								<!-- <el-col :span="24"> -->
									<ul class="filterConList">

										<li class="">
											<span>{{$t('c944a6686d996ab3')}}</span>
											<whNoSelect @changeData="changWhNo"></whNoSelect>
										</li>
										<li>
											<span>{{ $t('hytxs0000060') }}</span>
											<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
										</li>

										<!-- <li>
											<span>{{ $t('i18nn_d42355e6aef72227') }}</span>
											<el-date-picker
												v-model="filterData.daterange"
												size="small"
												type="daterange"
												align="right"
												:clearable="false"
												unlink-panels
												range-separator="-"
												start-placeholder="start date"
												end-placeholder="end date"
												:picker-options="pickerOptions"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd"
												@change="initData()"
											></el-date-picker>
										</li> -->
										<li class="">
											<span>SKU</span>
											<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small"
												v-model="filterData.goodsSku" maxlength="50" :clearable="true"
												style="width: 250px;"></el-input>
										</li>



										<li>
											<el-button icon="el-icon-search" size="small" type="primary"
												@click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
										</li>
									</ul>
									<!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee"  :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>                </el-select> -->
								<!-- </el-col> -->
							<!-- </el-row> -->
						</div>
					</div>

					<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
						:height="$store.state.tableMaxHeight2" @row-click="handleCurrentChange"
						@selection-change="handleSelectionChange" style="width: 100%" size="small">
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->
						<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
						<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip></el-table-column>

						<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>

						<el-table-column prop="stock" :label="$t('i18nn_de53ab10322cd38a')"></el-table-column>

						<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

						<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->

						<!-- <el-table-column :label="$t('93f5ca01b006206c')" width="430px" fixed="right">
							<template slot-scope="scope">
							
								<el-button @click="authAction($event, scope.row, scope.$index)" type="danger" size="mini" icon="el-icon-finished" v-if="'10'==scope.row.status">{{$t('i18nn_92c54ea7edec1dce')}}</el-button>
								
								<el-button @click="WhWriteReturnAction($event, scope.row)" type="warning" size="mini" icon="el-icon-edit">{{$t('i18nn_cc09153fb6e7d447')}}</el-button>
								
							</template>
						</el-table-column> -->
					</el-table>
				</div>
				<div class="tableConPagination">
					<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
				</div>
			</div>
		</div>
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhReportNowStockPageList" :expHttpFilter="pageFilterData()"></whExcelCustom>

	</div>
</template>
<script>
	// import WhGoods from './WhGoods.vue';
	// import WhPlace from '@/components/WarehouseCenter/WhPlace.vue';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';
	export default {
		components: {
			// WhPlace,
			cusSelFuzzy,
			whNoSelect,
			whExcelCustom
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: "",
				excelOption: {},
				// popTest : false,
				// pickerOptions: {
				// 	// disabledDate(time) {
				// 	//   return time.getTime() >= Date.now();
				// 	// },
				// 	shortcuts: [{
				// 			text: 'Latest Week',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Month',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		}
				// 		// {
				// 		//   text: 'Last Three Months',
				// 		//   onClick(picker) {
				// 		//     const end = new Date();
				// 		//     const start = new Date();
				// 		//     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				// 		//     picker.$emit('pick', [start, end]);
				// 		//   }
				// 		// }
				// 	]
				// },

				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),

				selectOption: {
					wh_no: [],
					// wh_goods_fee_type: [],
					// wh_vol_unit:[],
					// statusList: [
					// 	{
					// 		value: '',
					// 		label: this.$t('16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '10',
					// 		label:this.$t('7e14d2cd3996dcd2')
					// 	},
					// 	{
					// 		value: '45',
					// 		label:this.$t('i18nn_885c3cdb72cb2e0b')
					// 	}
					// ]
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					agentUser: '',
					// "accountPeriod":"",
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
					goodsSku: '',
					whNo: '',
					userId: ''
					// goodsSku: '',
					// goodsName:""
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
				// this.getDicData();
			},

			changCus(data) {
				console.log('changCus', data);
				this.filterData.userId = data.userId;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			//导出 excel
			exportExcel() {
				let columns = [{
						title: this.$t('5acbec83efb27445'),
						key: 'whNo'
					},
					{
						title: this.$t('hytxs0000060'),
						key: 'cusName'
					},
					{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: this.$t('i18nn_de53ab10322cd38a'),
						key: 'stock'
					},

				];
				let Data = this.tableData;

				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'InventoryNow';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();
				// excelUtilsNew.exportExcel(columns, Data, 'wh_InventoryStatistics', { height: 20 });
			},
			//分页的筛选项数据
			pageFilterData() {
				// console.log('daterange', this.filterData.daterange);
				// let startTime = '';
				// let endTime = '';
				// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				// 	startTime = this.filterData.daterange[0];
				// 	endTime = this.filterData.daterange[1];
				// } else {
				// 	this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
				// }

				return {
					userId: this.filterData.userId ? this.filterData.userId : null,

					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// "partnerAccount": this.$store.getters.getUserInfo.partnerId,
					// "cusNo": this.filterData.cusNo ? this.filterData.cusNo : null,
					// startTime: startTime ? startTime : null,
					// endTime: endTime ? endTime : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,

					whNo: this.filterData.whNo ? this.filterData.whNo : null
				};
			},
			//请求分页数据
			getPageData() {
				// let _this = this;



				this.loading_load = true;

				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.$http
					.put(this.$urlConfig.WhReportNowStockPageList, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//查询仓库
			// getWhNoData() {
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList)
			// 		.then(({ data }) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询仓库失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_323ee425eca7208c'));
			// 			this.$message.error('查询仓库失败,请重试！');
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_goods_fee_type'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-table__body {
	// 	.el-input {
	// 		input {
	// 			padding-left: 5px;
	// 			padding-right: 5px;
	// 		}
	// 	}

	// 	.el-input-number,
	// 	.el-select {
	// 		width: 100px;

	// 		.el-input__inner {
	// 			text-align: left;
	// 		}
	// 	}
	// }

	// .tb-in-row {
	// 	display: flex;

	// 	.tb-in-name {
	// 		width: 100px;
	// 		text-align: right;
	// 	}
	// }

	// .form_msg {
	// 	color: #e6a23c;
	// }

	// .simpleTable {
	//   width: 100%;
	//   border: 1px solid #e5e5e5;
	//   tr {
	//     td {
	//       border: 1px solid #e5e5e5;
	//     }
	//   }
	// }
</style>
